import { CommonModule } from '@angular/common';
import {
  Injectable,
  NgModule,
  Pipe,
  PipeTransform,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl | undefined {
    return this.sanitizer.sanitize(SecurityContext.HTML, url) ?? undefined;
  }
}

@NgModule({
  declarations: [SafePipe],
  imports: [CommonModule],
  exports: [SafePipe],
})
export class SafePipeModule {}
